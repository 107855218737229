<template>
  <div
    class="flex-grow flex items-stretch overflow-y-auto table rounded-5"
    :class="[theme, type]"
    :style="{ '--row-height': rowHeight }"
  >
    <!-- T I T L E -->
    <slot name="tableTitle">
      <p
        v-if="title"
        class="p-14"
      >
        {{ title }}
      </p>
    </slot>

    <div
      class="table-wrap overflow-auto"
      :style="`padding-right: ${tableScrollPadding}`"
      @scroll="(e) => infinityScroll ? onScroll(e) : null"
    >
      <table class="min-w-full overflow-auto">
        <thead class="table__headers">
          <tr>
            <th
              v-for="(header, i) in visibleColumns"
              :key="header.property"
              :style="`min-width: ${header.minWidth}px; width: ${header.width}px;`"
              class="table__header-wrapper"
              data-test="table-header-wrapper"
            >
              <div
                class="cell min-h-50"
                :style="`width: ${header.width}px;`"
                :class="{ 'cursor-pointer': header.sortable, 'with-border': i !== visibleColumns.length - 1}"
                data-test="table-header"
                @click="header.sortable && setSorting(header.property)"
              >
                <slot :name="`header_${header.property}`">{{ header.label }}</slot>

                <template v-if="header.sortable">
                  <div class="flex flex-col items-center relative ml-6 w-24 h-38">
                    <AppIcon
                      name="chevron"
                      class="absolute block top-0 transform rotate-180"
                      :class="{ 'opacity-40': !(sortBy === header.property && sortDirection === -1) }"
                      size="24"
                      data-test="table-chevron-up"
                    />
                    <AppIcon
                      name="chevron"
                      class="absolute block bottom-0"
                      :class="{ 'opacity-40': !(sortBy === header.property && sortDirection === 1) }"
                      size="24"
                      data-test="table-chevron-down"
                    />
                  </div>
                </template>
              </div>
            </th>
            <th
              v-if="showActions && $slots.actions"
              class="w-50"
              data-test="table-header-wrapper"
            >
              <div
                class="action-cell w-50"
                data-test="table-header"
              />
            </th>
          </tr>
        </thead>
        <tbody class="table__datasets overflow-auto">
          <template
            v-for="(row, i) in dataset"
            :key="i"
          >
            <tr
              :class="[rowClassChecker(row), rowClass]"
              data-test="table-row"
              @click="$emit('rowClick', row)"
            >
              <td
                v-for="(h) in visibleColumns"
                :key="h.property"
                :class="[{'truncate max-w-1': !!h.minWidth}, h.cellClasses]"
              >
                <div class="cell cell--height truncate">
                  <slot
                    :name="h.property"
                    :row="row"
                    :rowIndex="i"
                  >
                    <AppTruncatedTooltip
                      placement="bottom-start"
                      :content="generateValue(row, h.property)"
                    />
                  </slot>
                </div>
              </td>
              <td v-if="showActions && $slots.actions">
                <AppTooltip
                  placement="right-start"
                  trigger="click"
                  :offset="0"
                  :append-to-body="false"
                  tooltip-classes="bg-white rounded-5 font-medium shadow border border-solid border-grey-fp-20"
                >
                  <div class="cell cell--height">
                    <AppIcon
                      name="vertical-dots"
                      size="20"
                    />
                  </div>
                  <template #content="{ handleHidePopper }">
                    <slot
                      name="actions"
                      :row="row"
                      :rowIndex="i"
                      :handleHidePopper="handleHidePopper"
                    />
                  </template>
                </AppTooltip>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <slot
        v-if="!dataset.length"
        name="tableNoItems"
      />
      <div
        v-if="!dataset.length && !$slots.tableNoItems"
        class="flex items-center justify-center py-60 text-grey-fp-70"
      >
        No data
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType, watch, toRef, ref, toRefs } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { ITableSort, ITableHeaders, TIndexedObject } from '@/types';
  import { getNestedProp, sortArrayByKey } from '@/core/helper-functions';

  export default defineComponent({
    name: 'AppTable',

    components: { AppIcon, AppTruncatedTooltip, AppTooltip },

    props: {
      title: {
        type: String
      },

      headers: {
        type: Array as PropType<ITableHeaders[]>,
        required: true
      },

      dataset: {
        type: Array as PropType<TIndexedObject[]>,
        required: true
      },

      rowHeight: {
        type: String,
        default: '32px'
      },

      tableScrollPadding: {
        type: String,
        default: '0px'
      },

      sortFrontSide: {
        type: Boolean,
        default: false
      },

      rowClass: {
        type: String,
        default: 'cursor-pointer'
      },

      sort: {
        type: Object as PropType<ITableSort>,
        default: () => ({ order: '', orderBy: '' })
      },

      rowClassChecker: {
        type: Function,
        default: () => ''
      },

      theme: {
        type: String,
        default: 'primary',
        validator: (value: string) => ['primary', 'blue', 'orange', 'green'].indexOf(value) !== -1
      },

      type: {
        type: String,
        default: 'family',
        validator: (value: string) => ['family', 'admin'].indexOf(value) !== -1
      },

      showActions: {
        type: Boolean,
        default: false
      },

      infinityScroll: {
        type: Boolean,
        defaut: false
      },

      loading: {
        type: Boolean,
        default: false
      }
    },

    emits: ['rowClick', 'sortBy', 'update:dataset', 'scrolledDown'],

    setup(props, { emit }) {
      const sortBy = ref('');
      const sortDirection = ref(0);
      const { dataset, loading } = toRefs(props);

      const visibleColumns = computed(() => props.headers.filter((h) => h.show || h.show === undefined));

      watch(() => props.sort, (newValue: ITableSort) => {
        if (newValue) {
          sortBy.value = newValue.orderBy;
          sortDirection.value = newValue.order === 'desc' ? -1 : newValue.order === 'asc' ? 1 : 0;
        }
      });

      function generateValue(obj: TIndexedObject, key: string) {
        const value = getNestedProp(obj, key);

        return Array.isArray(value) ? value.join(', ') : value;
      }

      function onScroll ({ target }: any) {

        if (target.scrollTop + target.clientHeight >= target.scrollHeight - 1 && !loading.value) {
          emit('scrolledDown');
        }
      }

      function setSorting(sortByProp: string) {
        if (sortBy.value === sortByProp) {
          sortDirection.value++;
          switch (sortDirection.value) {
            case 0: sortBy.value = ''; break;
            case 2: sortDirection.value = -1; break;
          }
        } else {
          sortBy.value = sortByProp;
          sortDirection.value = 1;
        }

        let order = '';
        if (sortDirection.value === 1 || (sortDirection.value === 0 && sortBy.value)) order = 'asc';
        else if (sortDirection.value === -1) order = 'desc';

        if (props.sortFrontSide) {
          const sortedData = order
            ? toRef(props, 'dataset').value.sort(sortArrayByKey(sortBy.value, order))
            : dataset.value.slice();
          emit('update:dataset', sortedData);
        } else emit('sortBy', { orderBy: sortBy.value, order });
      }

      return {
        sortBy,
        sortDirection,
        visibleColumns,

        generateValue,
        setSorting,
        onScroll
      };
    }
  });
</script>

<style lang="scss" scoped>
  .table {
    @apply flex flex-col relative flex-1 w-full;

    &__headers {
      @apply bg-white mr-14 text-sm font-normal;
      th {
        @apply sticky top-0 z-10 bg-white;

        &:first-of-type {
          .cell {
            @apply pl-18;
          }
        }

        &:last-of-type {
          .cell {
            @apply pr-18;
          }
        }
      }
      .cell {
        @apply text-md font-normal flex items-center;
      }
    }

    &__datasets {
      @apply font-normal text-grey-fp-70;

      .cell {
        @apply md:px-14 py-3 flex items-center;
      }

      td {
        &:first-of-type {
          .cell {
            @apply pl-18;
          }
        }

        &:last-of-type {
          .cell {
            @apply pr-18;
          }
        }
      }

      .cell--height {
        height: var(--row-height);
        line-height: var(--row-height);
      }
    }

    &.family {
      @apply bg-white;

      table {
        border-collapse: separate;
        border-spacing: 0;
      }

      .table__headers {
        th {
          @apply border-b border-grey-fp-20;
        }

        .cell {
          @apply py-20 md:px-14 relative;
        }
      }

      tr:hover {
        @apply bg-grey-fp-10;
      }
    }

    &.admin {
      @apply bg-white;

      .table-wrap {
        @apply border border-grey-fp-20 rounded-5;
      }

      .table {
        &__header {
          &-wrapper {
            @apply bg-blue-ap-100;

            .cell {
              @apply py-6 px-5 md:px-14 text-white bg-transparent;

              &.with-border {
                &::after {
                  @apply absolute h-6/10 right-0 w-1 bg-white opacity-30;
                  content: "";
                }
              }
            }

            &:first-child {
              border-top-left-radius: 5px;
            }
            &:last-child {
              border-top-right-radius: 5px;
            }
          }
        }
      }

      tr:nth-child(even) {
        @apply bg-grey-fp-20;
      }

      &.primary {
        th {
          @apply bg-blue-ap-100;
        }

        tr {
          &:hover {
            @apply bg-blue-ap-50;
          }
        }
      }

      &.blue {
        th {
          @apply bg-blue-ap-100;
        }

        tr {
          &:hover {
            @apply bg-blue-ap-100;
          }
        }
      }

      &.orange {
        th {
          @apply bg-lucky-orange;
        }

        tr {
          &:hover {
            @apply bg-orange-ap-30;
          }
        }
      }

      &.green {
        th {
          @apply bg-green-ap-50;
        }

        tr {
          &:hover {
            @apply bg-green-ap-20;
          }
        }
      }
    }
  }
</style>
