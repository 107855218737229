
  import { computed, defineComponent, PropType, watch, toRef, ref, toRefs } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { ITableSort, ITableHeaders, TIndexedObject } from '@/types';
  import { getNestedProp, sortArrayByKey } from '@/core/helper-functions';

  export default defineComponent({
    name: 'AppTable',

    components: { AppIcon, AppTruncatedTooltip, AppTooltip },

    props: {
      title: {
        type: String
      },

      headers: {
        type: Array as PropType<ITableHeaders[]>,
        required: true
      },

      dataset: {
        type: Array as PropType<TIndexedObject[]>,
        required: true
      },

      rowHeight: {
        type: String,
        default: '32px'
      },

      tableScrollPadding: {
        type: String,
        default: '0px'
      },

      sortFrontSide: {
        type: Boolean,
        default: false
      },

      rowClass: {
        type: String,
        default: 'cursor-pointer'
      },

      sort: {
        type: Object as PropType<ITableSort>,
        default: () => ({ order: '', orderBy: '' })
      },

      rowClassChecker: {
        type: Function,
        default: () => ''
      },

      theme: {
        type: String,
        default: 'primary',
        validator: (value: string) => ['primary', 'blue', 'orange', 'green'].indexOf(value) !== -1
      },

      type: {
        type: String,
        default: 'family',
        validator: (value: string) => ['family', 'admin'].indexOf(value) !== -1
      },

      showActions: {
        type: Boolean,
        default: false
      },

      infinityScroll: {
        type: Boolean,
        defaut: false
      },

      loading: {
        type: Boolean,
        default: false
      }
    },

    emits: ['rowClick', 'sortBy', 'update:dataset', 'scrolledDown'],

    setup(props, { emit }) {
      const sortBy = ref('');
      const sortDirection = ref(0);
      const { dataset, loading } = toRefs(props);

      const visibleColumns = computed(() => props.headers.filter((h) => h.show || h.show === undefined));

      watch(() => props.sort, (newValue: ITableSort) => {
        if (newValue) {
          sortBy.value = newValue.orderBy;
          sortDirection.value = newValue.order === 'desc' ? -1 : newValue.order === 'asc' ? 1 : 0;
        }
      });

      function generateValue(obj: TIndexedObject, key: string) {
        const value = getNestedProp(obj, key);

        return Array.isArray(value) ? value.join(', ') : value;
      }

      function onScroll ({ target }: any) {

        if (target.scrollTop + target.clientHeight >= target.scrollHeight - 1 && !loading.value) {
          emit('scrolledDown');
        }
      }

      function setSorting(sortByProp: string) {
        if (sortBy.value === sortByProp) {
          sortDirection.value++;
          switch (sortDirection.value) {
            case 0: sortBy.value = ''; break;
            case 2: sortDirection.value = -1; break;
          }
        } else {
          sortBy.value = sortByProp;
          sortDirection.value = 1;
        }

        let order = '';
        if (sortDirection.value === 1 || (sortDirection.value === 0 && sortBy.value)) order = 'asc';
        else if (sortDirection.value === -1) order = 'desc';

        if (props.sortFrontSide) {
          const sortedData = order
            ? toRef(props, 'dataset').value.sort(sortArrayByKey(sortBy.value, order))
            : dataset.value.slice();
          emit('update:dataset', sortedData);
        } else emit('sortBy', { orderBy: sortBy.value, order });
      }

      return {
        sortBy,
        sortDirection,
        visibleColumns,

        generateValue,
        setSorting,
        onScroll
      };
    }
  });
